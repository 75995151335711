<dflgr-header-handler [title]="handlerTitle" [backButton]="true"/>
@if (countryList$ | async; as countryList) {
	<div class="list handler-content">
		@for (country of countryList; track country.id) {
			@if (isNewLetter(country.name)) {
				<h4 class="letter-separator" aria-label="Initial letter for the next countries"><span>{{currentLetter}}</span></h4>
			}
			<mat-card class="country-card">
				<mat-card-content>
					<dflgr-country-flag [country]="country"/>
					@if (country.city) {
						<div>{{country.city}}</div>
					}
				</mat-card-content>
			</mat-card>
		}
	</div>
} @else {
	<div class="spinner-container"><mat-spinner/></div>
}
