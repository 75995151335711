/* src/app/flags/handlers/countries-list-handler/countries-list-handler.component.scss */
.list {
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5px;
}
.list .letter-separator {
  padding-left: 5px;
  width: 100%;
}
.list .letter-separator span {
  cursor: default;
  background-color: yellow;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  display: flex;
  width: 40px;
  height: 40px;
  box-shadow: 1px 1px 3px silver;
}
.list .country-card {
  margin: 5px;
}
.list .country-card ::ng-deep .item-country-flag .flag-container img {
  width: 3em;
  height: 3em;
}
.list .country-card ::ng-deep .item-country-flag .name-container {
  font-size: 1.2em;
  display: block;
}
@media (min-width: 599px) {
  .list .country-card ::ng-deep .item-country-flag .name-container {
    margin-top: 0.5rem;
  }
}
@media (max-width: 599px) {
  .list .country-card {
    width: 100%;
  }
  .list .country-card mat-card-content {
    padding: 0 1rem 0.5rem 1rem;
  }
  .list .country-card ::ng-deep .item-country-flag .flag-container .flag-utf {
    font-size: 4em;
    line-height: 4.5rem;
  }
}
/*# sourceMappingURL=countries-list-handler.component.css.map */
